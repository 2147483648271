<template>
  <div v-if="studentGroupsFilter.length > 0">
    <v-btn-toggle v-model="groupFilter" multiple dense color="primary">
      <v-btn outlined disabled>
        <span class="primary--text">Gruppen</span>
      </v-btn>
      <v-btn
        outlined
        v-for="group in studentGroupsFilter"
        :value="group"
        :key="'group' + group"
        >{{ group }}</v-btn
      >
    </v-btn-toggle>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="reset"
          :disabled="groupFilter.length == 0"
          ><v-icon>mdi-filter-remove</v-icon>
        </v-btn>
      </template>
      <span>Filter zurücksetzen</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "StudentGroupsFilter",
  props: ["course"],
  data() {
    return {
      groupFilter: [],
      studentsFiltered: [],
    };
  },
  computed: {
    studentGroupsFilter() {
      if (!this.course.students || this.$_hasRole("student")) {
        return [];
      }
      return [
        ...new Set(
          this.course.students.reduce(
            (all, item) => [...all, ...item.studentGroups],
            []
          )
        ),
      ].sort();
    },
  },
  watch: {
    course() {
      this.updateStudents();
    },
    groupFilter() {
      this.updateStudents();
    },
    studentsFiltered() {
      this.$emit("input", this.studentsFiltered);
    },
  },
  methods: {
    reset() {
      this.groupFilter = [];
    },
    updateStudents() {
      if (!this.course.students) {
        this.studentsFiltered = [];
      } else if (this.groupFilter.length == 0) {
        this.studentsFiltered = this.course.students;
      } else {
        this.studentsFiltered = this.course.students.filter((el) =>
          el.studentGroups.some((el) => this.groupFilter.includes(el))
        );
      }
    },
  },
  created() {
    this.updateStudents();
  },
};
</script>
